<template>
  <div id="app" :class="'lang-'+$i18n.locale">
    <!-- <img alt="Vue logo" src="@/assets/logo.png"> -->
    <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
    <router-view></router-view>
  </div>
</template>

<script>
  export default {
    name: 'App',
    components: {},
    props: {
      active: String
    },
    data() {
      return {
        imgList: [
          // navbar
          "@/assets/bottom/logo.jpg",
          "@/assets/bottom/navbar-mobile-back.jpg",
          "@/assets/bottom/top-lang-arrow.jpg",
          "@/assets/bottom/top-menu-mobile.jpg",
          // bottom
          "@/assets/bottom/bottom-link-facebook.jpg",
          "@/assets/bottom/bottom-link-instagram.jpg",
          "@/assets/bottom/bottom-link-twiiter.jpg",
          "@/assets/bottom/bottom-link-wechat.jpg",
          "@/assets/bottom/bottom-logo.jpg",
          // index
          "@/assets/about/index-link-1.jpg",
          "@/assets/about/index-link-2.jpg",
          "@/assets/about/index-link-3.jpg",
          "@/assets/about/index-link-4.jpg",
          "@/assets/about/index-top-bg.jpg",
          "@/assets/about/index-video-poster.jpg",
          "@/assets/about/index-100Arabica.jpg",
          "@/assets/about/index-cat-1.jpg",
          "@/assets/about/index-cat-2.jpg",
          "@/assets/about/index-cat-3.jpg",
          "@/assets/about/index-cat-4.jpg",
          "@/assets/about/index-more-arrow.jpg",
          "@/assets/about/index-navigation-arrow.jpg",
          "@/assets/about/index-videobg-mobile.jpg",
          "@/assets/about/index-videobg-pc.jpg",
          // coffee
          "@/assets/about/coffee-banner.jpg",
          "@/assets/about/coffee-deafult.jpg",
          // contact
          "@/assets/about/contact-banner.jpg",
          "@/assets/about/contact-map.jpg",
          // about
          "@/assets/about/about-banner.jpg",
          "@/assets/about/about-img-1.jpg",
          "@/assets/about/about-img-2.jpg",
          "@/assets/about/about-img-3.jpg",
          "@/assets/about/about-img-4.jpg",
          // factory
          "@/assets/factory/factory-banner.jpg",
          "@/assets/factory/factory-img-1.jpg",
          "@/assets/factory/factory-img-2.jpg",
          "@/assets/factory/factory-img-3.jpg",
          "@/assets/factory/factory-img-4.jpg",
        ]
      }
    },
    mounted() {
      this.init()
    },
    methods: {
      init() {},
      preloadImg() {
        // 预加载图片资源 Todo
        var promiseAll = this.imgList.map(function (item, index) {
          // console.log(index)
          return new Promise(function (resolve, reject) {
            var img = new Image();
            img.onload = function () {
              img.onload = null;
              resolve(img);
            };
            img.error = function () {
              reject('img: ' + item + ' load fail');
            };
            img.src = item;
          });
        });
        Promise.all(promiseAll).then(
          function () {
            console.log("all img loaded")
          },
          function (err) {
            console.log(err);
          }
        );
      }
    }
  }
</script>

<style>
  html,
  body {
    margin: 0;
    padding: 0;
    transition: none !important;
  }

  html {
    box-sizing: border-box;
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
    transition: all 0.23s ease;
  }

  @media screen and (min-width:769px) {
    .lang-zh {
      font-family: system-ui, -apple-system, Arial, sans-serif;
    }

    .lang-en {
      font-family: system-ui, -apple-system, Arial, sans-serif;
    }

    .lang-es {
      font-family: system-ui, -apple-system, Arial, sans-serif;
    }
  }

  @media screen and (max-width:768px) {
    .lang-zh {
      font-family:  -apple-system, BlinkMacSystemFont, Tahoma, Arial, "Hiragino Sans GB", "Microsoft YaHei", "WenQuanYi Micro Hei", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    }

    .lang-en {
      font-family: -apple-system,"Helvetica Neue",Helvetica,Arial,sans-serif;
    }

    .lang-es {
      font-family: -apple-system,"Helvetica Neue",Helvetica,Arial,sans-serif;
    }
  }
</style>