// router.js 
// https://router.vuejs.org/zh/

import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

import Index from '../pages/index.vue'
import About from '../pages/about.vue'
import Factory from '../pages/factory.vue'
import Coffee from '../pages/coffee.vue'
import Contact from '../pages/contact.vue'

export default new VueRouter({
    mode:"hash",  //vue-router路由模式的默认方式
    routes:[
        // { path: '/user/:id', component: User }
        { name:"Home", path:"/", component:Index },
        { name:"About", path:"/about", component:About },
        { name:"Factory", path:"/factory", component:Factory },
        { name:"Coffee", path:"/coffee", component:Coffee },
        { name:"Contact", path:"/contact", component:Contact },
    ]
})