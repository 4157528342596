<template>
    <div class="banner">
        <div class="text">
            <span>
                <!-- 一 -->
               {{ $t("coffee.banner.text1") }}
            </span>
            <span>
                <!-- 豆 -->
               {{ $t("coffee.banner.text2") }}
            </span>
            <span>
                <!-- 一 -->
               {{ $t("coffee.banner.text3") }}
            </span>
            <span>
                <!-- 故 -->
               {{ $t("coffee.banner.text4") }}
            </span>
            <span>
                <!-- 事 -->
               {{ $t("coffee.banner.text5") }}
            </span>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'CoffeeBanner',
        props: {
            active: String
        },
        data() {
            return {
                loading: false
            }
        },
        mounted() {
            this.init()
        },
        methods: {
            init() {}
        }
    }
</script>

<style scoped>
    .banner {
        width: 100%;
        height: 480px;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        background-image: url('../assets/coffee/coffee-banner.jpg');
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .text{
        font-weight: bold;
        font-size: 36px;
        color: #FFFFFF;
        letter-spacing: 0;
        text-align: center;
    }
    .lang-zh .text{
        letter-spacing: 10px;
    }
    .text span{
        opacity: 0;
    }
    .text span:nth-child(1){
        animation: text-anime 1s ease 0s forwards;
    }
    .text span:nth-child(2){
        animation: text-anime 1s ease 0.3s forwards;
    }
    .text span:nth-child(3){
        animation: text-anime 1s ease 1s forwards;
    }
    .text span:nth-child(4){
        animation: text-anime 1s ease 1.3s forwards;
    }
    .text span:nth-child(5){
        animation: text-anime 1s ease 1.6s forwards;
    }

    @keyframes text-anime {
        0%{
            opacity: 0;
        }
        100%{
            opacity: 1;
        }
    }

    @media screen and (max-width:768px) {
        .banner {
            display: none;
        }
    }
</style>