<template>
    <div class="contant">
        <div class="info-box">
            <div class="imgbox">
                <img class="img-1" src="../assets/about/about-img-1.jpg" alt="">
                <img class="img-2" src="../assets/about/about-img-2.jpg" alt="">
            </div>
            <div class="textbox">
                <div class="title">
                    <div class="line"></div>
                    <div class="text">
                        <!-- 我们的选择 -->
                        {{ $t("about.title1") }}
                    </div>
                </div>
                <div class="intro">
                    <div class="intro-text" v-html="$t('about.intro1')">
                        <!-- 点到点，从庄园直采到工厂，我们严选每一粒咖啡豆原料，100%阿拉比卡。 -->
                    </div>
                </div>
            </div>
        </div>
        <div class="info-box">
            <div class="imgbox">
                <img class="single-img" src="../assets/about/about-img-3.jpg" alt="">
            </div>
            <div class="textbox">
                <div class="title">
                    <div class="line"></div>
                    <div class="text">
                        <!-- 我们的严格 -->
                        {{ $t("about.title2") }}
                    </div>
                </div>
                <div class="intro">
                    <div class="intro-text">
                        <!-- 线到线，从生产线稳定烘焙，严格机选加手选，纯天然无添加，直达您的手中。 -->
                        {{ $t("about.intro2") }}
                    </div>
                </div>
            </div>
        </div>
        <div class="info-box">

            <div class="imgbox">
                <img class="single-img" style="" src="../assets/about/about-img-4.jpg" alt="">
            </div>
            <div class="textbox">
                <div class="title">
                    <div class="line"></div>
                    <div class="text">
                        <!-- 给您的惊喜 -->
                        {{ $t("about.title3") }}
                    </div>
                </div>
                <div class="intro">
                    <div class="intro-text">
                        <!-- 面对面，感受每一颗成熟咖啡鲜果、每一粒咖啡豆，带来的果实“汁”味，为您甄选优质风味。 -->
                        {{ $t("about.intro3") }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'AboutContant',
        props: {
            active: String
        },
        data() {
            return {
                loading: false
            }
        },
        mounted() {
            this.init()
        },
        methods: {
            init() {}
        }
    }
</script>

<style scoped>
    .contant {
        width: 86%;
        max-width: 995px;
        margin: 120px auto 0;
    }

    .info-box {
        width: 100%;
        display: flex;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-top: 120px;
    }

    .info-box:first-child {
        margin-top: 0;
    }

    .info-box>div:first-child {
        margin-right: 30px;
    }


    .info-box:nth-child(2) {
        flex-direction: row-reverse;
    }

    .info-box>div:first-child {
        margin-right: 0;
    }

    .info-box>div:nth-child(2) {
        margin-right: 30px;
    }

    .imgbox {
        flex: 1;
        position: relative;
        padding-top: 0;
    }

    .info-box:first-child .imgbox {
        padding-top: 82px;
    }

    .imgbox .single-img {
        width: 100%;
        display: block;
    }

    .imgbox .img-1 {
        width: 57.6%;
        display: block;
        position: relative;
        z-index: 2;
        margin-left: -10px;
        border: 10px solid #fff;
        box-sizing: content-box;
    }

    .imgbox .img-2 {
        width: 57.6%;
        display: block;
        position: absolute;
        top: 0;
        left: 42.5%;
        z-index: 1;
    }

    .info-box:last-child .imgbox img {
        width: 78.8%
    }

    .textbox {
        flex: 1
    }

    .title {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 78.8%;
    }

    .title .line {
        width: 5px;
        height: 40px;
        background-color: #BF9D77;
        margin-right: 19px;
    }

    .title .text {
        font-weight: bold;
        font-size: 32px;
        color: #383838;
        letter-spacing: 0;
        line-height: 40px;
        flex: 1;
    }

    .intro {
        margin-top: 26px;
        width: 100%;
    }

    .intro-text {
        padding-left: 24px;
        font-size: 14px;
        color: #383838;
        letter-spacing: 0;
        text-align: left;
        line-height: 28px;
    }

    .lang-zh .intro-text{
        text-align: justify;
    }


    /* 1 */
    .info-box:first-child .textbox {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

    }

    .info-box:first-child .title {
        width: 78.8%;
    }

    .info-box:first-child .intro {
        width: 78.8%;
    }

    /* 2 */
    .info-box:nth-child(2) .title {
        width: 78.8%;
    }

    .info-box:nth-child(2) .intro {
        width: 78.8%;
    }

    @media screen and (max-width:768px) {
        .contant {
            margin: 54px auto 0;
        }

        .info-box {
            display: block;
            margin-top: 84px;
        }

        .info-box:first-child {
            margin-top: 0;
        }

        .info-box>div:first-child {
            margin-right: 0;
        }

        .info-box>div:nth-child(2) {
            margin-right: 0;
        }

        .imgbox {
            padding-top: 0;
        }

        .info-box:first-child .imgbox {
            padding-top: 53px;
        }

        .info-box:last-child .imgbox img {
            width: 100%
        }


        .textbox {
            flex: 1;
            margin-top: 32px;
        }

        .title {
            width: 100%;
        }

        .title .line {
            width: 3px;
            height: 25px;
            margin-right: 11px;
        }

        .title .text {
            font-weight: bold;
            font-size: 18px;
            color: #383838;
            letter-spacing: 0;
            line-height: 25px;
            flex: 1;
        }

        .intro {
            margin-top: 24px;
            font-size: 12px;
            width: 100%;
        }

        .intro-text {
            padding-left: 0;
            font-size: 12px;
        }

        /* 1 */
        .info-box:first-child .textbox {
            display: flex;
            flex-direction: column;
            align-items: flex-end;

        }

        .info-box:first-child .title {
            width: 100%;
        }

        .info-box:first-child .intro {
            width: 100%;
        }

        /* 2 */
        .info-box:nth-child(2) .title {
            width: 100%;
        }

        .info-box:nth-child(2) .intro {
            width: 100%;
        }
    }
</style>