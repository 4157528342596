<template>
    <div class="navigation" id="home-navigation">
        <div class="title">
            <div class="line"></div>
            <div class="text-box">
                <div class="logo">KOLAKOLA</div>
                <div class="text">
                    <!-- 甄选优质风味 -->
                    {{ $t("home.navigation.title") }}
                </div>
            </div>
            <div class="line"></div>
        </div>
        <div class="navi-box">
            <!-- 1 -->
            <div class="nav nav-1" @click="go('about')">
                <div class="nav-bg"></div>
                <div class="intro">
                    <div class="intro-text">
                        <div class="name">
                            <!-- 我 们 -->
                            {{ $t("home.navigation.nav1.title") }}
                        </div>
                        <div class="text">
                            <!-- KOLAKOLA 咖乐乐，WE PICK THE BEST FOR YOU  -->
                            {{ $t("home.navigation.nav1.intro") }}
                        </div>
                    </div>
                    <img class="arrow" src="../assets/index/index-navigation-arrow.png" alt="">
                </div>
                <!-- <div class="pc-title">我 们</div> -->
            </div>
            <!-- 2 -->
            <div class="nav nav-2" @click="go('coffee')">
                <div class="nav-bg"></div>
                <div class="intro">
                    <div class="intro-text">
                        <div class="name">
                            <!-- 咖 啡 -->
                            {{ $t("home.navigation.nav2.title") }}
                        </div>
                        <div class="text">
                            <!-- 为您呈送咖啡的果实“汁”味 -->
                            {{ $t("home.navigation.nav2.intro") }}
                        </div>
                    </div>
                    <img class="arrow" src="../assets/index/index-navigation-arrow.png" alt="">
                </div>
                <!-- <div class="pc-title">咖 啡</div> -->
            </div>
            <!-- 3 -->
            <div class="nav nav-3" @click="go('factory')">
                <div class="nav-bg"></div>
                <div class="intro">
                    <div class="intro-text">
                        <div class="name">
                            <!-- 工 厂 -->
                            {{ $t("home.navigation.nav3.title") }}
                        </div>
                        <div class="text">
                            <!-- 匠心烘焙，呈现优质风味。客订配方，为您定制独家配方。 -->
                            {{ $t("home.navigation.nav3.intro") }}
                        </div>
                    </div>
                    <img class="arrow" src="../assets/index/index-navigation-arrow.png" alt="">
                </div>
                <!-- <div class="pc-title">工 厂</div> -->
            </div>
            <!-- 4 -->
            <div class="nav nav-4" @click="go('contact')">
                <div class="nav-bg"></div>
                <div class="intro">
                    <div class="intro-text">
                        <div class="name">
                            <!-- 联系方式 -->
                            {{ $t("home.navigation.nav4.title") }}
                        </div>
                        <div class="text">
                            <!-- 欢迎与联系我们，了解更多关于KolaKola的故事。 -->
                            {{ $t("home.navigation.nav4.intro") }}
                        </div>
                    </div>
                    <img class="arrow" src="../assets/index/index-navigation-arrow.png" alt="">
                </div>
                <!-- <div class="pc-title">联系方式</div> -->
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Navigation',
        props: {
            active: String
        },
        data() {
            return {
                loading: false
            }
        },
        mounted() {
            this.init()
        },
        methods: {
            init() {},
            go(where){
                this.$router.push('/'+where)
                // 返回顶部
                document.body.scrollIntoView()
            }
        }
    }
</script>

<style scoped>
    .navigation {
        width: 86%;
        max-width: 1200px;
        padding-top: 120px;
        margin: 0 auto;
    }

    /* 标题 */
    .title {
        display: flex;
        flex-direction: row;

    }

    .line {
        flex: 1;
        background: #909090;
        height: 1px;
        width: 100%;
        margin-top: 45px;
    }

    .text-box {
        padding: 0 44px;
    }

    .text-box .logo {
        font-size: 14px;
        color: #727272;
        letter-spacing: 5.83px;
    }

    .text-box .text {
        font-weight: bold;
        font-size: 36px;
        color: #383838;
        letter-spacing: 0;
    }

    .lang-zh .text-box .text {
        letter-spacing: 15px;
    }

    /* 图片区域 */
    .navi-box {
        width: 100%;
        display: flex;
        flex-direction: row;
        margin-top: 120px;
        /* margin-bottom: 120px; */
    }

    .nav {
        flex: 1;
        height: 360px;
        margin-right: 12px;
        position: relative;
        cursor: pointer;
        padding-bottom: 18px;
        overflow: hidden;
    }

    .nav:last-child {
        margin-right: 0;
    }

    .nav .nav-bg{
        position: relative;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }

    .nav-1 .nav-bg {
        background-image: url(../assets/index/index-link-1.jpg)
    }

    .nav-2 .nav-bg {
        background-image: url(../assets/index/index-link-2.jpg)
    }

    .nav-3 .nav-bg {
        background-image: url(../assets/index/index-link-3.jpg)
    }

    .nav-4 .nav-bg {
        background-image: url(../assets/index/index-link-4.jpg)
    }

    /* PC */
    .nav .intro {
        position: absolute;
        background: rgba(191, 157, 119, 1);
        transition: all 0.5s ease;
        /* bottom: -21px; */
        bottom: 0;
        left: 50%;
        width: 180px;
        height: 42px;
        margin-left: -90px;
        /* margin-top: -21px; */
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        padding: 0;
    }

    .nav .intro-text {
        width: 100%;
    }

    .nav .name {
        color: #fff;
        font-weight: bold;
        font-size: 21px;
        color: #FFFFFF;
        /* letter-spacing: 11px; */
        /* margin-left: 11px; */
        transition: all 0.5s ease;
        text-align: center;
    }

    /* .nav.nav-4 .name{
        letter-spacing: 0;
        margin-left: 0;
    } */
    .nav .text {
        overflow: hidden;
        height: 0px;
        font-size: 14px;
        color: #FFFFFF;
        letter-spacing: 0;
        transition: all 0.5s ease;
        margin-top: 0;
        opacity: 0;
    }

    .nav .arrow {
        width: 20px;
        display: block;
        position: absolute;
        right: 400px;
        bottom: 24px;
        opacity: 0;
        transition: all 1s ease;
    }

    @media screen and (min-width:769px) {

        /* hover */
        .nav:hover .intro {
            bottom: 18px;
            left: 0;
            margin-left: 0;
            margin-top: 0;
            width: 100%;
            height: 100%;
            overflow: hidden;
            background: rgba(191, 157, 119, 0.82);
            padding: 24px;
        }

        .nav:hover .name {
            letter-spacing: 0;
            margin-left: 0;
            text-align: left;
        }

        .nav:hover .text {
            height: auto;
            margin-top: 8px;
            opacity: 1;
        }

        .nav:hover .arrow {
            right: 30px;
            bottom: 24px;
            opacity: 1;
        }
    }

    @media screen and (max-width:768px) {
        .navigation {
            width: 86%;
            padding-top: 28px;
        }

        /* 标题 */
        .title {
            display: flex;
            flex-direction: row;
        }

        .line {
            margin-top: 29px;
        }

        .text-box {
            padding: 0 14px;
        }

        .text-box .logo {
            font-size: 12px;
            color: #727272;
            letter-spacing: 3.33px;
            -webkit-text-size-adjust:none;
            transform : scale(0.66,0.66);
            position: relative;
            left: -25px;
        }

        .text-box .text {
            font-weight: bold;
            font-size: 18px;
            color: #383838;
            letter-spacing: 0;
        }

        .lang-zh .text-box .text {
            letter-spacing: 7.5px;
        }

        /* 图片区域 */
        .navi-box {
            display: block;
            margin-top: 28px;
        }

        .nav {
            width: 100%;
            height: 478px;
            margin-right: 0;
            margin-bottom: 10px;
        }
        .nav:last-child{
            margin-bottom: 0;
        }

        /* mobile */
        .nav .intro {
            background: rgba(191, 157, 119, 0.82);
            position: absolute;
            /* top: none; */
            left: 0;
            bottom: 18px;
            width: 100%;
            height: auto;
            margin-left: 0;
            margin-top: 0;
            /* display: flex; */
            flex-direction: row;
            align-items: center;
            justify-content: center;
            /* overflow: hidden; */
            padding: 18px 27px 15px;
        }

        .nav .intro-text {
            flex:1;
            width: none;
        }

        .nav .name {
            line-height: 22px;
            text-align: left;
        }
        .nav .text {
            height: auto;
            margin-top: 12px;
            opacity: 1;
        }

        .nav .arrow {
            position: relative;
            right: 0;
            bottom: 0;
            opacity: 1;
            margin-left: 7px;
        }
    }
</style>