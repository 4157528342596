<template>
    <div class="banner">
        <div class="banner-box">
            <div class="top">
                <div class="line"></div>
                <div class="text-box">
                    <p class="big-text">
                        <!-- 从庄园到工厂 -->
                        {{ $t("home.banner.bigText1") }}
                    </p>
                    <p class="big-text">
                        <!-- 100%阿拉比卡咖啡豆 -->
                        {{ $t("home.banner.bigText2") }}
                    </p>
                    <p class="small-text">
                        <!-- 严选每一粒咖啡豆原料 -->
                        {{ $t("home.banner.smallText") }}
                    </p>
                </div>
            </div>
            <div class="btn" @click="scrollNav()">
                <div class="text">
                    <!-- 更多 -->
                    {{ $t("home.banner.more") }}
                </div>
                <img class="arrow" src="../assets/index/index-more-arrow.png" alt="">
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'HomeBanner',
        props: {
            active: String
        },
        data() {
            return {
                loading: false
            }
        },
        mounted() {
            this.init()
        },
        methods: {
            init() {},
            scrollNav(){
                document.getElementById("home-navigation").scrollIntoView(
                    { block: "start", behavior: "smooth" }
                );
            }
        }
    }
</script>

<style scoped>
    .banner {
        width: 100%;
        height: 678px;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        background-image: url('../assets/index/index-top-bg.jpg');
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        /* background-attachment: fixed */
    }

    @supports (height: 100vh){
        .banner {height: 100vh;}
    }

    .banner-box {
        width: 86%;
        max-width: 1200px;
        /* background-color: rgba(0,0,0,.5); */
    }

    .banner-box .top {
        display: flex;
        flex-direction: row;
    }

    .banner-box .line {
        background: #BF9D77;
        width: 10px;
        height: 120px;
        margin-right: 32px;
    }

    .banner-box .text-box {
        flex: 1;
    }

    .banner-box .big-text {
        font-size: bold;
        font-size: 36px;
        line-height: 47px;
        color: #FFFFFF;
        margin: 0;
    }

    .banner-box .small-text {
        font-size: 14px;
        line-height: 20px;
        color: #FFFFFF;
        margin: 8px 0 0;
    }

    .banner-box .btn {
        background: rgba(255, 255, 255, 0.15);
        border: 1px solid #FFFFFF;
        width: 80px;
        height: 32px;
        margin: 26px 0 0 40px;
        display: block;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0 12px 0 14px;
        cursor: pointer;
    }
    .banner-box .btn:hover{
        background: rgba(0,0,0,0.15);
    }

    .banner-box .text {
        flex: 1;
        color: #fff;
        font-size: 16px;
    }

    .banner-box .arrow {
        width: 6px;
        display: block;
        margin-top: 1px;
    }

    @media screen and (max-width:768px) {
        .banner {
            height: 360px;
            background-size: 180%;
        }

        .banner-box {
            width: 86%;
        }

        .banner-box .line {
            width: 6px;
            height: 96px;
            margin-right: 8px;
        }

        .banner-box .big-text {
            font-size: 21px;
            line-height: 29px;
        }

        .lang-zh .banner-box .big-text {
            font-size: 24px;
            line-height: 34px;
        }

        .banner-box .small-text {
            margin: 10px 0 0;
            font-size: 12px;
            line-height: 16px;
        }

        .lang-zh .banner-box .small-text {
            font-size: 14px;
            line-height: 20px;
        }

        .banner-box .btn {
            display: none;
        }
    }
</style>