<template>
    <div :class="catFixed ? 'coffeelist fixed' :'coffeelist'">
        <div class="cat">
            <div class="cat-wrap">
                <!-- <div
                :class="item.id == cat_selected ? 'cat-name cat-'+index+' active' : 'cat-name cat-'+index"
                v-for="(item,index) in cat"
                @click="changeCat(item.id)">
                    {{item.name_zhcn  || ''}}
                </div> -->
                <div :class="cat_selected == 2 ? 'cat-name cat-0 active' : 'cat-name cat-0'" @click="changeCat(2)">
                    <!-- 拼配 -->
                    {{ $t("coffee.list.cat1") }}
                </div>
                <div :class="cat_selected == 1 ? 'cat-name cat-1 active' : 'cat-name cat-1'" @click="changeCat(1)">
                    <!-- 单品 -->
                    {{ $t("coffee.list.cat2") }}
                </div>
                <div :class="cat_selected == 3 ? 'cat-name cat-2 active' : 'cat-name cat-2'" @click="changeCat(3)">
                    <!-- 甄选 -->
                    {{ $t("coffee.list.cat3") }}
                </div>
                <div :class="cat_selected == 4 ? 'cat-name cat-3 active' : 'cat-name cat-3'" @click="changeCat(4)">
                    <!-- 其他 -->
                    {{ $t("coffee.list.cat4") }}
                </div>
                <div class="cat-line"></div>
            </div>
        </div>
        <div class="cat-blank"></div>
        <div class="list">
            <Loading :loading="!coffeeInfo || coffeeInfo.length < 1" />
            <div class="caffee-box" v-for="item in coffeeInfo"
                v-if="coffeeInfo && coffeeInfo.length > 0 && item.coffee_classifications[0].id == cat_selected">
                <div class="cover">
                    <img :src="item.cover_image.url ? item.cover_image.url+'?x-oss-process=image/resize,m_lfit,w_800/quality,Q_90/format,jpg' : '../assets/coffee/coffee-deafult.jpg'"
                        alt="">
                </div>
                <div class="info-box">
                    <div class="title">
                        <span v-if="$i18n.locale == 'zh'">{{item.name_zhcn || ''}}</span>
                        <span v-if="$i18n.locale == 'en'">{{item.name_en || ''}}</span>
                        <span v-if="$i18n.locale == 'es'">{{item.name_es || ''}}</span>
                    </div>
                    <div class="baseinfo-box">
                        <!-- 1 -->
                        <div class="bacseinfo" v-if="item.origin_zhcn">
                            <div class="key">
                                <!-- 产 地:  -->
                                {{ $t("coffee.list.origin") }}
                            </div>
                            <div class="value">
                                <span v-if="$i18n.locale == 'zh'">{{item.origin_zhcn || ''}}</span>
                                <span v-if="$i18n.locale == 'en'">{{item.origin_en || ''}}</span>
                                <span v-if="$i18n.locale == 'es'">{{item.origin_es || ''}}</span>
                            </div>
                        </div>
                        <!-- 2 -->
                        <div class="bacseinfo" v-if="item.varietal_zhcn">
                            <div class="key">
                                <!-- 豆 种:  -->
                                {{ $t("coffee.list.varietal") }}
                            </div>
                            <div class="value">
                                <span v-if="$i18n.locale == 'zh'">{{item.varietal_zhcn || ''}}</span>
                                <span v-if="$i18n.locale == 'en'">{{item.varietal_en || ''}}</span>
                                <span v-if="$i18n.locale == 'es'">{{item.varietal_es || ''}}</span>
                            </div>
                        </div>
                        <!-- 3 -->
                        <div class="bacseinfo" v-if="item.roasted">
                            <div class="key">
                                <!-- 烘焙程度:  -->
                                {{ $t("coffee.list.roasted") }}
                            </div>
                            <div class="value">
                                <span v-if="$i18n.locale == 'zh'">{{item.roasted.roasted_name_zhcn || ''}}</span>
                                <span v-if="$i18n.locale == 'en'">{{item.roasted.roasted_name || ''}}</span>
                                <span v-if="$i18n.locale == 'es'">{{item.roasted.roasted_name_es || ''}}</span>
                            </div>
                        </div>
                        <!-- 4 -->
                        <div class="bacseinfo" v-if="item.process">
                            <div class="key">
                                <!-- 处理方式:  -->
                                {{ $t("coffee.list.process") }}
                            </div>
                            <div class="value">
                                <span v-if="$i18n.locale == 'zh'">{{item.process.process_name_zhcn || ''}}</span>
                                <span v-if="$i18n.locale == 'en'">{{item.process.process_name || ''}}</span>
                                <span v-if="$i18n.locale == 'es'">{{item.process.process_name_es || ''}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="line"></div>
                    <div class="desc" v-if="item.description_zhcn">
                        <div class="desc-key">
                            <!-- 风味描述:  -->
                            {{ $t("coffee.list.description") }}
                        </div>
                        <div class="desc-value">
                            <span v-if="$i18n.locale == 'zh'">{{item.description_zhcn || ''}}</span>
                            <span v-if="$i18n.locale == 'en'">{{item.description_en || ''}}</span>
                            <span v-if="$i18n.locale == 'es'">{{item.description_es || ''}}</span>
                        </div>
                    </div>
                    <div class="box-score"
                        v-if="item.aroma && item.acidity && item.sweetness && item.bitter && item.body && item.aroma != 0 && item.acidity != 0 && item.sweetness != 0 && item.bitter != 0 && item.body != 0">
                        <div class="score-name">
                            <p>
                                <!-- 香 气 -->
                                {{ $t("coffee.list.aroma") }}
                            </p>
                            <p>
                                <!-- 酸 质 -->
                                {{ $t("coffee.list.acidity") }}
                            </p>
                            <p>
                                <!-- 甜 感 -->
                                {{ $t("coffee.list.sweetness") }}
                            </p>
                            <p>
                                <!-- 苦 感 -->
                                {{ $t("coffee.list.bitter") }}
                            </p>
                            <p>
                                <!-- 醇 厚 -->
                                {{ $t("coffee.list.body") }}
                            </p>
                        </div>
                        <div class="score-line">
                            <!-- 横线 -->
                            <div class="r-line-box">
                                <div class="r-line" :style="'width:'+20*item.aroma+'%'"></div>
                            </div>
                            <div class="r-line-box">
                                <div class="r-line" :style="'width:'+20*item.acidity+'%'"></div>
                            </div>
                            <div class="r-line-box">
                                <div class="r-line" :style="'width:'+20*item.sweetness+'%'"></div>
                            </div>
                            <div class="r-line-box">
                                <div class="r-line" :style="'width:'+20*item.bitter+'%'"></div>
                            </div>
                            <div class="r-line-box">
                                <div class="r-line" :style="'width:'+20*item.body+'%'"></div>
                            </div>
                            <!-- 竖线 -->
                            <div class="bg-box">
                                <div class="v-line-box">
                                    <div class="text">1</div>
                                </div>
                                <div class="v-line-box">
                                    <div class="text">2</div>
                                </div>
                                <div class="v-line-box">
                                    <div class="text">3</div>
                                </div>
                                <div class="v-line-box">
                                    <div class="text">4</div>
                                </div>
                                <div class="v-line-box">
                                    <div class="text">5</div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <a v-if="$i18n.locale == 'zh' && item.goodslink_zhcn" :href="item.goodslink_zhcn" target="_blank"
                        class="buy-btn">去购买</a>
                    <a v-if="$i18n.locale == 'en' && item.goodslink_en" :href="goodslink_en" target="_blank"
                        class="buy-btn">Buy</a>
                    <a v-if="$i18n.locale == 'es' && item.goodslink_es" :href="goodslink_es" target="_blank"
                        class="buy-btn">Comprar</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Loading from "../components/Loading"

    export default {
        components: {
            Loading
        },
        name: 'CoffeeList',
        props: {
            coffeeInfo: Array,
            cat: Array,
        },
        data() {
            return {
                loading: false,
                cat_selected: 2,
                catFixed: false,
            }
        },
        mounted() {
            window.addEventListener('scroll', this.watchScroll);
            this.init()
        },
        methods: {
            init() {
                let c = this.$route.query.c
                if (c) {
                    this.changeCat(c)
                }
            },
            changeCat(id) {
                this.cat_selected = id
                let toplenght
                let width = document.body.clientWidth
                if (width <= 768) {
                    toplenght = 0
                } else {
                    toplenght = 480
                }
                window.scrollTo({
                    top: toplenght,
                    behavior: "smooth"
                })
            },
            watchScroll() {
                var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
                let width = document.body.clientWidth
                //  当滚动超过 50px 时，实现吸顶效果(滚动条高度当前设置为：50px)
                if (width > 768) {
                    if (scrollTop > 480) {
                        this.catFixed = true;
                    } else {
                        this.catFixed = false;
                    }
                } else {
                    if (scrollTop > 2) {
                        this.catFixed = true;
                    } else {
                        this.catFixed = false;
                    }
                }
            },
        }
    }
</script>

<style scoped>
    .coffeelist {
        width: 86%;
        max-width: 1200px;
        display: flex;
        flex-direction: row;
        margin: 123px auto 0;
    }

    .cat {
        width: 100px;
        margin-right: 29px;
        margin-top: -4px;
        position: relative;
    }

    .cat-wrap {
        width: 100%;
        height: auto;
        position: relative;
    }

    .cat-blank {
        display: none;
        width: 100px;
        margin-right: 29px;
        margin-top: -4px;
        position: relative;
    }

    .cat-name {
        font-weight: bold;
        font-size: 18px;
        color: #636363;
        letter-spacing: 0;
        line-height: 56px;
        cursor: pointer;
        word-break: keep-all;
        white-space: nowrap;
    }

    .lang-zh .cat-name {
        letter-spacing: 7.5px;
    }

    @media screen and (min-width:769px) {
        .coffeelist.fixed .cat {
            position: fixed;
            top: 123px;
            z-index: 8;
        }

        .coffeelist.fixed .cat-blank {
            display: block;
        }

        .cat-name.active,
        .cat-name:hover {
            font-size: 24px;
            color: #383838;
            padding-left: 17px;
        }
        .lang-zh .cat-name.active,
        .lang-zh .cat-name:hover {
            letter-spacing: 10px;
        }


        .cat-line {
            width: 5px;
            height: 32px;
            background-color: #BF9D77;
            position: absolute;
            top: 0;
            left: 0
        }

        .cat-0:hover~.cat-line,
        .cat-0.active~.cat-line {
            top: 12px
        }

        .cat-1:hover~.cat-line,
        .cat-1.active~.cat-line {
            top: 68px
        }

        .cat-2:hover~.cat-line,
        .cat-2.active~.cat-line {
            top: 124px
        }

        .cat-3:hover~.cat-line,
        .cat-3.active~.cat-line {
            top: 180px
        }
    }

    .list {
        width: 1px;
        flex: 1
    }

    .caffee-box {
        margin-bottom: 188px;
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .caffee-box:last-child {
        margin-bottom: 22px;
    }


    .cover {
        flex: 1;
    }

    .cover img {
        width: 100%;
    }

    .info-box {
        flex: 1;
        width: 1px;
        padding-left: 1px;
    }

    .info-box .title {
        font-weight: bold;
        font-size: 24px;
        color: #383838;
        letter-spacing: 0;
    }

    .info-box .baseinfo-box {
        margin-top: 33px;
        display: flex;
        flex-wrap: wrap;
    }

    .info-box .bacseinfo {
        width: 50%;
        margin-bottom: 27px;
        padding-right: 27px;
    }

    .info-box .key {
        font-size: 14px;
        color: #949494;
        line-height: 20px;
        letter-spacing: 0;
    }

    .info-box .value {
        margin-top: 8px;
        font-size: 16px;
        line-height: 20px;
        color: #383838;
        letter-spacing: 0;
    }

    .info-box .line {
        margin: 9px 0 36px;
        width: 100%;
        height: 1px;
        background-color: #D5D5D5;
    }

    .info-box .desc {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: center;
        width: 87%;
    }

    .info-box .desc-key {
        font-weight: bold;
        font-size: 16px;
        color: #383838;
        letter-spacing: 0;
    }

    .lang-zh .info-box .desc-key {
        letter-spacing: 6.67px;
    }

    .info-box .desc-value {
        flex: 1;
        font-size: 14px;
        color: #383838;
        letter-spacing: 0;
        text-align: right;
    }

    .box-score {
        display: flex;
        flex-direction: row;
        width: 87%;
        margin-top: 24px;
    }

    .score-name p {
        font-size: 14px;
        color: #636363;
        letter-spacing: 0;
        line-height: 32px;
        margin: 0 19px 0 0;
    }

    .score-line {
        position: relative;
        flex: 1;
        /* background-color: #efefef; */
    }

    .r-line-box {
        position: relative;
        width: 100%;
        height: 32px;
        display: flex;
        flex-direction: row;
        align-items: center;
        z-index: 2;
    }

    .r-line {
        width: 100%;
        height: 8px;
        background-color: #BF9D77;
    }

    .bg-box {
        width: 100%;
        height: 98%;
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .v-line-box {
        height: 100%;
        flex: 1;
        border-right: 1px solid #CCCCCC;
        position: relative;
    }

    .v-line-box .text {
        width: 100%;
        font-weight: bold;
        font-size: 14px;
        line-height: 20px;
        color: #636363;
        letter-spacing: 0;
        text-align: center;
        /* background-color: #efefef; */
        position: absolute;
        left: 50%;
        bottom: -22px;
    }

    .buy-btn {
        display: block;
        text-decoration: none;
        width: 100%;
        height: 40px;
        margin-top: 55px;
        line-height: 40px;
        background-color: #BF9C77;
        color: #fff;
        text-align: center;
    }

    .buy-btn:hover {
        transform: scale(1.02, 1.02);
        box-shadow: rgba(0, 0, 0, .1) 0 5px 10px;
    }


    @media screen and (max-width:768px) {
        .coffeelist {
            width: 86%;
            max-width: 1200px;
            display: block;
            margin: 96px auto 0;
        }

        /* .cat {
            width: 100%;
            margin-right: 0;
            margin-top: 0;
        } */

        .cat-wrap {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
        }

        /* .cat-blank{
            display: none;
            width: 100%;
            margin-right: 0;
            margin-top: 0;
        } */

        .cat {
            position: fixed;
            width: 100%;
            padding: 0;
            top: 96px;
            left: 0;
            z-index: 8;
            background-color: #fff;
        }

        .lang-zh .cat{
            padding: 0 7%;
        }

        .coffeelist.fixed .cat {
            top: 80px;
        }

        .cat-blank {
            width: 100%;
            margin-right: 0;
            margin-top: 0;
            display: block;
            height: 72px;
        }

        .cat-name {
            flex: 1;
            font-weight: normal;
            font-size: 12px;
            color: #b6a5a5;
            letter-spacing: 0;
            text-align: center;
            line-height: 72px;
            cursor: pointer;
        }

        .lang-zh .cat-name {
            font-size: 16px;
        }

        .cat-name.active {
            padding-left: 0;
            font-weight: bold;
            font-size: 14px;
            color: #383838;
            letter-spacing: 0;
            text-align: center;
        }

        .lang-zh .cat-name.active {
            font-size: 18px;
        }

        .cat-line {
            width: 36px;
            height: 4px;
            background-color: #BF9D77;
            position: absolute;
            top: 50px;
            left: 0;
            margin-left: -18px;
        }

        .cat-0.active~.cat-line {
            top: 50px;
            left: 12.5%;
        }

        .cat-1.active~.cat-line {
            top: 50px;
            left: 37.5%;
        }

        .cat-2.active~.cat-line {
            top: 50px;
            left: 62.5%;
        }

        .cat-3.active~.cat-line {
            top: 50px;
            left: 87.5%;
        }

        .list {
            width: 100%;
        }

        .caffee-box {
            margin-bottom: 86px;
            width: 100%;
            display: block;
        }

        .caffee-box:last-child {
            margin-bottom: 86px;
        }

        .cover {
            width: 100%;
        }

        .info-box {
            width: 100%;
            padding-left: 0;
        }

        .info-box .title {
            font-weight: bold;
            font-size: 18px;
            color: #383838;
            letter-spacing: 0;
        }

        .info-box .baseinfo-box {
            margin-top: 24px;
            display: flex;
            flex-wrap: wrap;
        }

        .info-box .bacseinfo {
            width: 70%;
            margin-bottom: 24px;
            padding-right: 24px;
        }

        .info-box .bacseinfo:nth-child(2n) {
            width: 30%;
            padding-right: 0;

        }

        .info-box .key {
            font-size: 12px;
            color: #949494;
            line-height: 17px;
            letter-spacing: 0;
        }

        .info-box .value {
            margin-top: 6px;
            font-size: 12px;
            line-height: 17px;
            color: #383838;
            letter-spacing: 0;
        }

        .info-box .line {
            margin: 0 0 26px;
            width: 100%;
            height: 1px;
            background-color: #D5D5D5;
        }

        .info-box .desc {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            width: 91.2%;
        }

        .info-box .desc-key {
            font-size: 14px;
            letter-spacing: 0;
        }

        .lang-zh .info-box .desc-key {
            letter-spacing: 5.83px;
        }


        .info-box .desc-value {
            font-size: 12px;
        }

        .box-score {
            width: 91.2%;
            margin-top: 19px;
        }

        .score-name p {
            font-size: 12px;
            line-height: 29px;
            margin: 0 16px 0 0;
        }

        .r-line-box {
            height: 29px;
        }
    }
</style>