<template>
    <div :class="loading ? 'loading' : 'loading hidden'">
        <div class="spinner"></div>
    </div>
</template>

<script>
    export default {
        name: 'HomeVideo',
        props: {
            loading: {
                type: Boolean,
                default: true
            }
        },
        components: {},
        mounted() {
        },
        methods: {
        }
    }
</script>

<style scoped>
    .loading{
        width: 100%;
        height: 250px;
        max-height: 100%;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        animation: all 0.3s ease;
    }
    .loading.hidden{
        height: 0;
        opacity: 0;
    }
    .spinner {
    width: 40px;
    height: 40px;
    margin: 100px auto;
    background-color: #333;

    border-radius: 100%;  
    -webkit-animation: sk-scaleout 1.0s infinite ease-in-out;
    animation: sk-scaleout 1.0s infinite ease-in-out;
    }

    @-webkit-keyframes sk-scaleout {
    0% { -webkit-transform: scale(0) }
    100% {
        -webkit-transform: scale(1.0);
        opacity: 0;
    }
    }

    @keyframes sk-scaleout {
    0% { 
        -webkit-transform: scale(0);
        transform: scale(0);
    } 100% {
        -webkit-transform: scale(1.0);
        transform: scale(1.0);
        opacity: 0;
    }
    }
</style>