<template>
    <div class="bottom">
        <div class="main">
            <div class="logo">
                <img src="../assets/bottom/bottom-logo.png" alt="">
            </div>
            <div class="links">
                <p @click="go('coffee')">
                    <!-- 咖啡 -->
                    {{ $t("bottom.link1") }}
                </p>
                <p @click="go('factory')">
                    <!-- 工厂 -->
                    {{ $t("bottom.link2") }}
                </p>
                <p @click="go('about')">
                    <!-- 关于 -->
                    {{ $t("bottom.link3") }}
                </p>
                <p @click="go('contact')">
                    <!-- 联系我们 -->
                    {{ $t("bottom.link4") }}
                </p>
            </div>
            <div class="contact">
                <p class="title">
                    <!-- 联系方式 -->
                    {{ $t("bottom.contact.title") }}
                </p>
                <p>
                    <!-- 地址：中国江苏省南京市 -->
                    {{ $t("bottom.contact.addressTitle") }}{{ $t("bottom.contact.addressDesc") }}
                </p>
                <p>
                    <!-- 电话：<a href="tel:008617712407551">+86 17712407551</a> -->
                    {{ $t("bottom.contact.phoneTitle") }}<a :href="'tel:'+$t('bottom.contact.phoneHref')">{{ $t("bottom.contact.phoneDesc") }}</a>
                </p>
                <p>
                    <!-- 邮箱：<a href="mailto:info@kolakolacoffee.com">info@kolakolacoffee.com</a> -->
                    {{ $t("bottom.contact.emailTitle") }}<a :href="'mailto:'+$t('bottom.contact.emailDesc')">{{ $t("bottom.contact.emailDesc") }}</a>
                </p>
            </div>
            <div class="social-network">
                <!-- <a target="_blank" href=""><img src="../assets/bottom/bottom-link-wechat.png" alt=""></a> -->
                <a target="_blank" href="https://www.instagram.com/kolakolacoffee"><img src="../assets/bottom/bottom-link-instagram.png" alt=""></a>
                <a target="_blank" href="https://m.facebook.com/Kolakola-Coffee-101188185317009"><img src="../assets/bottom/bottom-link-facebook.png" alt=""></a>
                <!-- <a target="_blank" href=""><img src="../assets/bottom/bottom-link-twiiter.png" alt=""></a> -->
            </div>
        </div>
        <div class="line"></div>
        <div class="icp">
            © 2021 KOLAKOLA <a href="https://beian.miit.gov.cn" target="_blank">
            <!-- 苏ICP备2020064708号-1 -->
            {{ $t("bottom.icp") }}
            </a>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Bottom',
        props: {
            active: String
        },
        data() {
            return {
                loading: false
            }
        },
        mounted() {
            this.init()
        },
        methods: {
            init() {},
            go(where){
                this.$router.push('/'+where)
                // 返回顶部
                document.body.scrollIntoView()
            }
        }
    }
</script>

<style scoped>
    .bottom {
        margin-top: 180px;
        background-color: #34302F;
        padding: 96px 0 49px;
    }

    .main {
        width: 86%;
        max-width: 1200px;
        margin: 0 auto;
        position: relative;
    }

    .logo {
        position: absolute;
        top: 0;
        left: 0;
        width: 166px;
    }

    .logo img {
        display: block;
        width: 100%;
    }

    .links {
        position: absolute;
        width: 27.5%;
        right: 0;
        padding-top: 41px;
    }

    .links p {
        font-size: 14px;
        color: #FFFFFF;
        letter-spacing: 0;
        line-height: 36px;
        margin: 0;
        cursor: pointer;
    }
    .links p:hover{
        transform: translateX(2px);
    }

    .contact {
        position: relative;
        width: 38.1%;
        margin: 0 auto;
        padding-top: 41px;
    }

    .contact p {
        font-size: 14px;
        color: #FFFFFF;
        letter-spacing: 0;
        line-height: 36px;
        margin: 0;
    }

    .contact p.title {
        color: #999797;

    }

    .contact p a {
        text-decoration: none;
        color: #FFFFFF;
    }

    .social-network {
        position: absolute;
        top: 57px;
        left: 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    .social-network a {
        display: block;
        text-decoration: none;
        width: 36px;
        margin-right: 12px;
    }

    .social-network a:last-child {
        margin-right: 0;
    }

    .social-network a:hover {
        transform: scale(1.1, 1.1) translateY(-1px);
    }

    .social-network a img {
        display: block;
        width: 100%;
    }

    .line {
        width: 86%;
        height: 1px;
        background-color: #5C5958;
        margin: 0 auto;
        margin-top: 49px;
        margin-bottom: 12px;
    }

    .icp {
        width: 100%;
        text-align: center;
        color: #999797;
        font-size: 14px;
    }

    .icp a {
        color: #999797;
        text-decoration: none;
    }

    .icp a:hover {
        color: #fff;
    }

    @media screen and (max-width:768px) {
        .bottom {
            margin-top: 51px;
            padding: 36px 0 24px;
        }

        .main {
            width: 86%;
        }

        .logo {
            position: relative;
        }

        .links {
            position: relative;
            width: 100%;
            padding-top: 37px;
        }
        .contact {
            position: relative;
            width: 100%;
            padding-top: 35px;
        }

        .social-network {
            position: relative;
            top: 0;
            margin-top: 48px;
            width: 100%;
        }

        .social-network a {
            display: block;
            text-decoration: none;
            width: 36px;
            margin-right: 40px;
        }
    }
</style>