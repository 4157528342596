import Vue from 'vue'
import App from './App.vue'
import router from "./router/index";
import VueGtag from "vue-gtag";

// gtag
Vue.use(VueGtag, {
  config: { id: "G-TS9BEKKWZV" },
},router);

// amap
import AmapVueConfig from '@amap/amap-vue/lib/config';
AmapVueConfig.key = '51dce8721163b5467e8c578c86ba1ad5';

Vue.config.productionTip = false

// i18n
import VueI18n from 'vue-i18n'
Vue.use(VueI18n)

import i18n from './i18n'

new Vue({
  router,
  i18n,
  render: h => h(App),
}).$mount('#app')
