<template>
  <div class="container">
    <Navbar active="about" class="component-navber" />
    <AboutBanner />
    <AboutContant />
    <Bottom />
  </div>
</template>

<script>
  import Navbar from "../components/Navbar"
  import AboutBanner from "../components/AboutBanner"
  import AboutContant from "../components/AboutContant"
  import Bottom from "../components/Bottom"
  export default {
    components: {
      Navbar,Bottom,AboutBanner,AboutContant
    },
    name: 'Index',
    // props: {
    //   msg: String
    // },
    data() {
      return {
        loading: false
      }
    },
    mounted() {
      this.init()
    },
    methods: {
      init() {}
    }
  }
</script>

<style scoped>
  .component-navber {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
  }

  @media screen and (max-width:768px) {
    .component-navber {
      display: block;
      width: 100%;
      height: 96px;
      background-color: #34302F;
    }
  }
</style>