import Vue from 'vue'
import VueI18n from 'vue-i18n'
// import Cookie from 'vue-cookie'

// Vue.use(Cookie)
Vue.use(VueI18n)

let loc = localStorage.getItem('lang')

if(!loc || loc == ''){
  // 获取浏览器语言
  let lang = navigator.language || navigator.userLanguage
  lang = lang.substr(0, 2)

  if (lang == 'zh'){
    loc = 'zh'
  } else if (lang == 'es'){
    loc = 'es'
  } else {
    loc = 'en'
  }
  localStorage.setItem('lang', loc)
}

console.log("lang")
console.log(loc)

const i18n = new VueI18n({
  locale: loc || 'en',
  // locale: 'zh',
  messages: {
    en: require('./en.json'),
    zh: require('./zh_CN.json'),
    es: require('./es.json')
  }
})

export default i18n