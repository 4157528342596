<template>
  <div class="container">
    <Navbar active="factory" class="component-navber" />
    <FactoryBanner />
    <FactoryContant />
    <FactorySwiper />
    <FactoryVideo />
    <Bottom />
  </div>
</template>

<script>
  import Navbar from "../components/Navbar"
  import FactoryBanner from "../components/FactoryBanner"
  import FactoryContant from "../components/FactoryContant"
  import FactorySwiper from "../components/FactorySwiper"
  import FactoryVideo from "../components/FactoryVideo"
  import Bottom from "../components/Bottom"
  export default {
    components: {
      Navbar,Bottom,FactoryBanner,FactoryContant,FactorySwiper,FactoryVideo
    },
    name: 'Index',
    // props: {
    //   msg: String
    // },
    data() {
      return {
        loading: false
      }
    },
    mounted() {
      this.init()
    },
    methods: {
      init() {}
    }
  }
</script>

<style scoped>
  .component-navber {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
  }

  @media screen and (max-width:768px) {
    .component-navber {
      display: block;
      width: 100%;
      height: 96px;
      background-color: #34302F;
    }
  }
</style>