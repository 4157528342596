<template>
    <div class="coffe-intro">
        <div class="main-box">
            <div class="title">
                <div class="line"></div>
                <div class="text">
                    <div class="small">KOLAKOLA</div>
                    <div class="big">
                        <!-- 咖啡豆 -->
                         {{ $t("home.coffeeintro.coffee") }}
                    </div>
                </div>
            </div>
            <div class="number">
                <div class="number-block"></div>
                <img class="number-img" src="../assets/index/index-100Arabica.png" alt="">
            </div>
            <div class="more" @click="go('coffee')">
                <!-- 了解更多 -->
                {{ $t("home.coffeeintro.more") }} ></div>
        </div>
        <div class="intro-box">
            <div class="intro" @click="go('coffee',2)">
                <div class="img-box">
                    <img src="../assets/index/index-cat-1.png" alt="">
                    <div class="cat-block"></div>
                </div>
                <div class="text-box">
                    <div class="cat">
                        <!-- 拼配 -->
                        {{ $t("home.coffeeintro.title1") }}
                    </div>
                    <div class="desc">
                        <!-- 综合多个原产地的独家配方丰富你的味蕾 -->
                        {{ $t("home.coffeeintro.intro1") }}
                    </div>
                </div>
            </div>
            <div class="intro" @click="go('coffee',1)">
                <div class="img-box">
                    <img src="../assets/index/index-cat-2.png" alt="">
                    <div class="cat-block"></div>
                </div>
                <div class="text-box">
                    <div class="cat">
                        <!-- 单品 -->
                        {{ $t("home.coffeeintro.title2") }}
                    </div>
                    <div class="desc">
                        <!-- 源自单一产地感受独特地域的风味 -->
                        {{ $t("home.coffeeintro.intro2") }}
                    </div>
                </div>
            </div>
            <div class="intro" @click="go('coffee',3)">
                <div class="img-box">
                    <img src="../assets/index/index-cat-3.png" alt="">
                    <div class="cat-block"></div>
                </div>
                <div class="text-box" @click="go('coffee?c=1')">
                    <div class="cat">
                        <!-- 甄选 -->
                        {{ $t("home.coffeeintro.title3") }}
                    </div>
                    <div class="desc">
                        <!-- La Mejor 甄选极致风味单品咖啡 -->
                        {{ $t("home.coffeeintro.intro3") }}
                    </div>
                </div>
            </div>
            <div class="intro" @click="go('coffee',4)">
                <div class="img-box">
                    <img src="../assets/index/index-cat-4.png" alt="">
                    <div class="cat-block"></div>
                </div>
                <div class="text-box">
                    <div class="cat">
                        <!-- 其他 -->
                        {{ $t("home.coffeeintro.title4") }}
                    </div>
                    <div class="desc">
                        <!-- 喝点不一样的 -->
                        {{ $t("home.coffeeintro.intro4") }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'HomeCoffeeIntro',
        props: {
            active: String
        },
        data() {
            return {
                loading: false
            }
        },
        mounted() {
            this.init()
        },
        methods: {
            init() {},
            go(where,cat){
                
                this.$router.push({
                    path:'/'+where,
                    query: { c:cat }
                })
                // 返回顶部
                document.body.scrollIntoView()
            }
        }
    }
</script>

<style scoped>
    .coffe-intro {
        width: 86%;
        max-width: 1200px;
        margin: 120px auto 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    .main-box {
        flex: 2;
        margin-right: 30px;
    }

    .main-box .title {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    .main-box .line {
        height: 64px;
        width: 5px;
        background-color: #BF9D77;
        margin-right: 19px;
    }

    .main-box .text {
        flex: 1;
    }

    .main-box .small {
        font-weight: bold;
        font-size: 16px;
        color: #383838;
        letter-spacing: 0;
        line-height: 22px;
    }

    .main-box .big {
        font-weight: bold;
        font-size: 32px;
        color: #383838;
        letter-spacing: 0;
        line-height: 48px;
    }

    .main-box .number {
        margin-top: 28px;
        position: relative;
        padding-left: 25px;
    }

    .main-box .number-img {
        width: 236px;
        display: block;
        z-index: 2;
        position: relative;
    }

    .main-box .number-block {
        width: 73px;
        height: 73px;
        background-color: #BF9D77;
        position: absolute;
        left: 0;
        bottom: 0;
    }

    .main-box .more {
        font-weight: bold;
        font-size: 16px;
        color: #383838;
        letter-spacing: 0;
        margin: 24px 0 0 25px;
        cursor: pointer;
    }


    .main-box .more:hover {
        transform: translateX(4px);
    }

    .intro-box {
        flex: 3;
        position: relative;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }

    .intro-box .intro {
        width: 50%;
        height: 126px;
        padding-right: 52px;
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;
    }


    .intro-box .img-box {
        position: relative;
        margin-right: 16px;
        height: 72px;
    }


    .intro-box .img-box img {
        display: block;
        width: 72px;
        z-index: 2;
        position: relative;
    }

    .intro-box .img-box .cat-block {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 36px;
        height: 36px;
        background-color: #BF9D77;
    }

    .intro-box .text-box {
        flex: 1;
    }

    .intro-box .intro:hover .text-box {
        transform: translateX(4px);
    }

    .intro-box .cat {
        font-size: 18px;
        color: #383838;
        letter-spacing: 0;
        font-weight: bold;
    }

    .intro-box .desc {
        font-size: 14px;
        color: #383838;
        letter-spacing: 0;
    }

    @media screen and (max-width:768px) {
        .coffe-intro {
            width: 86%;
            margin: 48px auto 0;
            display: block;
        }

        .main-box {
            flex: 2;
            margin-right: 30px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
        }

        .main-box .title {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            flex:1;
        }

        .main-box .line {
            height: 36px;
            width: 3px;
            background-color: #BF9D77;
            margin-right: 8px;
        }

        .main-box .text {
            flex: 1;
        }

        .main-box .small {
            font-size: 12px;
            line-height: 12px;
            -webkit-text-size-adjust:none;
            transform : scale(0.83,0.83);
            position: relative;
            left: -8%;
        }

        .main-box .big {
            font-size: 18px;
            line-height: 18px;
            margin-top: 7px;
        }

        .main-box .number {
            margin-top: 0;
            padding-left: 0;
        }

        .main-box .number-img {
            width: 75px;
            display: block;
            z-index: 2;
            position: relative;
        }

        .main-box .number-block {
            width: 22px;
            height: 22px;
            background-color: #BF9D77;
            position: absolute;
            left: 0;
            bottom: 0;
        }

        .main-box .more {
            display: none;
        }
        
        .intro-box .intro {
            width: 45%;
            height: auto;
            margin: 36px auto 0;
            padding-right: 0;
            display: block
        }
        .intro-box .intro:nth-child(2n){
            margin-left: 10%;
        }

        .intro-box .intro:first-child{
            margin-top: 36px;
        }

        .intro-box .img-box {
            width: 60px;
            height: 60px;
            margin:  0 auto;
        }

        .intro-box .img-box img {
            display: block;
            width: 60px;
            z-index: 2;
            position: relative;
        }

        .intro-box .img-box .cat-block {
            position: absolute;
            bottom: 0;
            right: 0;
            width: 36px;
            height: 36px;
            background-color: #BF9D77;
        }

        .intro-box .text-box {
            flex: 1;
        }

        .intro-box .cat {
            font-weight: bold;
            font-size: 16px;
            text-align: center;
            margin-top: 10px;
        }

        .intro-box .desc {
            font-size: 12px;
            text-align: center;
            margin-top: 7px;
        }
    }
</style>