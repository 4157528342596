<template>
    <div class="banner">
    </div>
</template>

<script>
    export default {
        name: 'ContactBanner',
        props: {
            active: String
        },
        data() {
            return {
                loading: false
            }
        },
        mounted() {
            this.init()
        },
        methods: {
            init() {}
        }
    }
</script>

<style scoped>
    .banner {
        width: 100%;
        height: 480px;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        background-image: url('../assets/contact/contact-banner.jpg');
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    @media screen and (max-width:768px) {
        .banner {
            display: none;
        }
    }
</style>