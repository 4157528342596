<template>
    <div :class="navBarFixed ? 'navbar fixed' : 'navbar'">
        <div class="wrap">
            <div class="logo" @click="go('')">
                <img src="../assets/navbar/logo.png" alt="">
            </div>
            <div class="navbox">
                <div @click="go('')" :class="active == 'index' ? 'nav index active':'nav index'">
                    <!-- 首页 -->
                    {{ $t("navbar.pc.home") }}
                </div>
                <div @click="go('factory')" :class="active == 'factory' ? 'nav factory active':'nav factory'">
                    <!-- 工厂 -->
                    {{ $t("navbar.pc.factory") }}
                </div>
                <div @click="go('coffee')" :class="active == 'coffee' ? 'nav coffee active':'nav coffee'">
                    <!-- 咖啡 -->
                    {{ $t("navbar.pc.coffee") }}
                </div>
                <div @click="go('about')" :class="active == 'about' ? 'nav about active':'nav about'">
                    <!-- 关于 -->
                    {{ $t("navbar.pc.about") }}
                </div>
                <div @click="go('contact')" :class="active == 'contact' ? 'nav contact active':'nav contact'">
                    <!-- 联系 -->
                    {{ $t("navbar.pc.contact") }}
                </div>
                <div :class="'line nav-'+active"></div>
            </div>
            <!-- 多语言切换 -->
            <div class="lang">
                <div class="selectbox">
                    <span v-if="lang == 'zh'">中文</span>
                    <span v-if="lang == 'en'">English</span>
                    <span v-if="lang == 'es'">Español</span>
                    <img src="../assets/navbar/top-lang-arrow.png" alt="">
                </div>
                <div class="optionbox">
                    <div class="option" @click="changeLang('zh')">中文</div>
                    <div class="option" @click="changeLang('en')">English</div>
                    <div class="option" @click="changeLang('es')">Español</div>
                </div>
            </div>
            <div class="mobile-btn" @click="openMobileMenu =true">
                <img src="../assets/navbar/top-menu-mobile.png" alt="">
            </div>
            <div class="mobile-menu" v-if="openMobileMenu">
                <div class="back-btn" @click="openMobileMenu =false">
                    <img src="../assets/navbar/navbar-mobile-back.png" alt="">
                </div>
                <div @click="go('')" :class="active == 'index' ? 'nav index active':'nav index'"
                    style="animation-delay: 0s;">
                    <div class="text">
                        <!-- 首页 -->
                        {{ $t("navbar.mobile.home") }}
                    </div>
                    <div class="line"></div>
                </div>
                <div @click="go('factory')" :class="active == 'factory' ? 'nav factory active':'nav factory'"
                    style="animation-delay: 0.1s;">
                    <div class="text">
                        <!-- 工厂 -->
                        {{ $t("navbar.mobile.factory") }}
                    </div>
                    <div class="line"></div>
                </div>
                <div @click="go('coffee')" :class="active == 'coffee' ? 'nav coffee active':'nav coffee'"
                    style="animation-delay: 0.2s;">
                    <div class="text">
                        <!-- 咖啡 -->
                        {{ $t("navbar.mobile.coffee") }}
                    </div>
                    <div class="line"></div>
                </div>
                <div @click="go('about')" :class="active == 'about' ? 'nav about active':'nav about'"
                    style="animation-delay: 0.3s;">
                    <div class="text">
                        <!-- 关于 -->
                        {{ $t("navbar.mobile.about") }}
                    </div>
                    <div class="line"></div>
                </div>
                <div @click="go('contact')" :class="active == 'contact' ? 'nav contact active':'nav contact'"
                    style="animation-delay: 0.4s;">
                    <div class="text">
                        <!-- 联系 -->
                        {{ $t("navbar.mobile.contact") }}
                    </div>
                    <div class="line"></div>
                </div>
            </div>

            <!-- 导航栏 {{active}} -->
        </div>

    </div>
</template>

<script>
    export default {
        name: 'Navbar',
        props: {
            active: String
        },
        data() {
            return {
                loading: false,
                openMobileMenu: false,
                lang: "",
                navBarFixed: false,
            }
        },
        mounted() {
            window.addEventListener('scroll', this.watchScroll);
            this.init()
        },
        methods: {
            init() {
                this.lang = this.$i18n.locale
            },
            changeLang(loc) {
                console.log(loc)
                localStorage.setItem("lang", loc)
                this.lang = loc;
                this.$i18n.locale = loc;
            },
            watchScroll() {
                var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
                //  当滚动超过 50px 时，实现吸顶效果(滚动条高度当前设置为：50px)
                if (scrollTop > 2) {
                    this.navBarFixed = true;
                } else {
                    this.navBarFixed = false;
                }
            },
            go(where) {
                this.$router.push('/' + where)
                document.body.scrollIntoView()
            }
        }
    }
</script>

<style scoped>
    .navbar {
        width: 100%;
        /* mobile:96px */
        /* background-color: rgba(0, 0, 0, .5); */
    }

    .wrap {
        height: 114px;
        width: 86%;
        max-width: 1200px;
        margin: 0 auto;
        padding: 0;
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .navbar.fixed {
        position: fixed;
        top: 0;
        background-color: rgba(52,48,47,1);
        height: 80px;
    }

    .navbar.fixed .wrap {
        height: 80px;
    }

    /* Logo */
    .logo {
        width: 199px;
        height: 114px;
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;
    }

    .logo:hover {
        transform: scale(1.05, 1.05);
    }

    .logo img {
        width: 100%;
        display: block;
    }

    /* 菜单栏 */
    .navbox {
        flex: 1;
        display: flex;
        margin-left: 7.4%;
        position: relative;
    }

    /* 导航 */
    .navbox .nav {
        width: 86px;
        font-size: 16px;
        color: #FFFFFF;
        letter-spacing: 0;
        text-align: center;
        margin-right: 16px;
        cursor: pointer;
        height: 114px;
        line-height: 114px;
    }

    .navbox .nav:last-child {
        margin-right: 0;
    }

    .navbox .nav:hover {
        color: #BF9D77;
    }

    .navbox .line {
        width: 40px;
        height: 4px;
        background-color: #BF9D77;
        position: absolute;
        top: 76px;
        transition: all 0.3s ease 0.05s;
    }

    .navbox .line.nav-index,
    .nav.index:hover~.line {
        transform: translateX(23px);
    }

    .navbox .line.nav-factory,
    .nav.factory:hover~.line {
        transform: translateX(125px);
        /* left: 125px; */
    }

    .navbox .line.nav-coffee,
    .nav.coffee:hover~.line {
        transform: translateX(227px);
        /* left: 227px; */
    }

    .navbox .line.nav-about,
    .nav.about:hover~.line {
        transform: translateX(329px);
        /* left: 329px; */
    }

    .navbox .line.nav-contact,
    .nav.contact:hover~.line {
        transform: translateX(431px);
        /* left: 431px; */
    }

    /* 语言选择栏 */
    .lang {
        position: relative
    }

    .lang:hover .optionbox {
        display: block;
    }

    .selectbox {
        width: 96px;
        height: 32px;
        background: rgba(255, 255, 255, 0.15);
        border: 1px solid #FFFFFF;
        color: #fff;
        /* font-weight: bold; */
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

    .selectbox:hover {
        background: rgba(0, 0, 0, 0.15);
    }

    .selectbox span {
        display: block;
    }

    .selectbox img {
        width: 10px;
        display: block;
        margin-left: 5px;
    }

    .optionbox {
        display: none;
        position: absolute;
        width: 96px;
        top: 32px;
        left: 0;
        background-color: #BF9D77;
    }

    .optionbox .option {
        height: 32px;
        color: #fff;
        /* font-weight: bold; */
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

    .optionbox .option:hover {
        background-color: #B48F6D;
    }

    /* 手机端汉堡导航 */
    .mobile-btn {
        display: none;
    }

    .mobile-menu {
        display: none;
    }

    /* @media screen and (max-width:768px) { */
    /* Todo 可以优化平板显示 */
    @media screen and (max-width:1020px) {
        .wrap {
            height: 96px;
            width: 100%;
        }

        /* Logo */
        .logo {
            height: 96px;
            flex: 1;
        }

        .logo img {
            margin-left: 7%;
            width: 182px;
            height: auto;
            display: block;
        }


        /* 菜单栏 */
        .navbox {
            display: none;
        }

        /* 语言选择栏 */
        .lang {
            display: none;
        }

        /* 手机端汉堡导航 */

        .mobile-btn {
            display: flex;
            width: 96px;
            height: 96px;
            align-items: center;
            justify-content: center;
            cursor: pointer;
        }

        .mobile-btn img {
            width: 24px;
            display: block;
        }

        .mobile-menu {
            display: flex;
            flex-direction: column;
            position: absolute;
            width: 100vw;
            height: 100vh;
            left: 0;
            top: 0;
            background-color: rgba(52, 48, 47);
        }

        .back-btn {
            width: 100%;
            height: 96px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-end;
            transform: translateX(96px);
            animation: back-btn-anime 0.6s ease 0.6s forwards;
        }

        @keyframes back-btn-anime {
            0% {
                transform: translateX(96px)
            }

            100% {
                transform: translateX(0)
            }
        }

        .back-btn img {
            width: 29px;
            margin-right: 7%;
        }

        .mobile-menu .nav {
            width: 100%;
            padding: 17px 15%;
            transform: translateY(96px);
            opacity: 0;
            animation: mobile-nav-anime 0.6s ease forwards;

        }

        @keyframes mobile-nav-anime {
            0% {
                transform: translateY(96px);
                opacity: 0;
            }

            100% {
                transform: translateY(0);
                opacity: 1;
            }
        }

        .mobile-menu .nav .text {
            font-size: 18px;
            line-height: 25px;
            font-weight: bold;
            color: #fff;
        }

        .mobile-menu .nav .line {
            margin-top: 2px;
            width: 0;
            height: 6px;
            background-color: #BF9D77;
            display: none;
            /* transform: scaleX(0); */
            transform-origin: left;
            animation: mobile-nav-line-anime 0.6s ease 0.7s forwards;
        }

        .mobile-menu .nav.active .line {
            display: block;
        }

        @keyframes mobile-nav-line-anime {
            0% {
                width: 0;
            }

            100% {
                width: 45px;
            }
        }
    }
</style>