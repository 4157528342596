<template>
    <div class="map-contant">
        <div class="box-map">
            <!-- pc -->
            <div class="map-pc" id="map-container">
                <!-- Todo 多语言 -->
                <amap class="amap" :zoom="14" :center="position">
                    <amap-marker :position="position" />
                </amap>
                <!-- test.kolakolacoffee.com
                    www.kolakolacoffee.com
                    kolakolacoffee.com -->
            </div>
            <!-- mobile -->
            <!-- 
                http://restapi.amap.com/v3/staticmap?location=118.92695,31.908806&zoom=14&size=375*420&scale=2&markers=large,0xFF0000,A:118.92695,31.908806&labels=KolaKola,3,0,40,0x000000,0xFFFFFF:118.92695,31.908806&key=b5c665a9a92b7f247f25dfda3eef7b6c
                http://restapi.amap.com/v3/staticmap?location=118.92695,31.908806&zoom=14&size=375*420&scale=2&markers=large,2,0xffffff,0x2C80FF,1:118.92695,31.908806&key=b5c665a9a92b7f247f25dfda3eef7b6c
             -->
            <img class="map-mobile"
                src="http://restapi.amap.com/v3/staticmap?location=118.92695,31.908806&zoom=14&size=375*420&scale=2&markers=large,0x2C80FF,1:118.92695,31.908806&key=b5c665a9a92b7f247f25dfda3eef7b6c"
                alt="">
        </div>
        <div class="box-text">
            <div class="wrap">
                <div class="title">
                    <div class="line"></div>
                    <div class="text">
                        <!-- 联系我们 -->
                        {{ $t("contact.title") }}
                    </div>
                </div>
                <div class="info">
                    <div class="icon">
                        <img src="../assets/contact/icon-address.png" alt="">
                        <div class="block"></div>
                    </div>
                    <div class="textbox">
                        <div class="key">
                            <!-- 地址 -->
                            {{ $t("contact.addressTitle") }}
                        </div>
                        <div class="value">
                            <!-- 中国江苏省南京市 -->
                            {{ $t("contact.addressDesc") }}
                        </div>
                    </div>
                </div>
                <a class="info" :href="'mailto:'+$t('contact.emailDesc')">
                    <div class="icon">
                        <img src="../assets/contact/icon-email.png" alt="">
                        <div class="block"></div>
                    </div>
                    <div class="textbox">
                        <div class="key">
                            <!-- 邮箱 -->
                            {{ $t("contact.emailTitle") }}
                        </div>
                        <div class="value">
                            <!-- info@kolakolacoffee.com -->
                            {{ $t("contact.emailDesc") }}
                        </div>
                    </div>
                </a>
                <a class="info" :href="'tel:'+$t('contact.phoneHref')" >
                    <div class="icon">
                        <img src="../assets/contact/icon-phone.png" alt="">
                        <div class="block"></div>
                    </div>
                    <div class="textbox">
                        <div class="key">
                            <!-- 电话 -->
                            {{ $t("contact.phoneTitle") }}
                        </div>
                        <div class="value">
                            <!-- +86 17712407551 -->
                            {{ $t("contact.phoneDesc") }}
                        </div>
                    </div>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
    import Amap from '@amap/amap-vue/lib/amap';
    import AmapMarker from '@amap/amap-vue/lib/marker';
    export default {
        name: 'ContactMap',
        components: {
            Amap,
            AmapMarker,
        },
        props: {
            active: String
        },
        data() {
            return {
                position: [118.92695, 31.908806]
            }
        },
        mounted() {
            this.init()
        },
        methods: {
            init() {},
            loadMap() {
                let width = document.body.clientWidth
                if (width > 768) {
                    var map = new AMap.Map('map-container', {
                        zoom: 11, //级别
                        center: [116.397428, 39.90923], //中心点坐标
                        viewMode: '3D' //使用3D视图
                    });
                    var url =
                        'https://webapi.amap.com/maps?v=1.4.15&key=51dce8721163b5467e8c578c86ba1ad5&callback=onLoad';
                    var jsapi = doc.createElement('script');
                    jsapi.charset = 'utf-8';
                    jsapi.src = url;
                    document.head.appendChild(jsapi);
                }
            }
        }
    }
</script>

<style scoped>
    .map-contant {
        width: 86%;
        max-width: 1170px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin: 120px auto 0;
    }

    .box-map {
        flex: 1;
    }

    .map-pc {
        width: 100%;
        position: relative;
        background: #ccc;
    }

    .map-pc:after {
        content: "";
        display: block;
        padding-bottom: 100%;
    }

    .map-pc .amap {
        position: absolute !important;
        width: 100%;
        height: 100%;
    }

    .map-mobile {
        display: none;
    }

    .box-text {
        /* flex: 1; */
        max-width: 50%;
    }

    .box-text .wrap {
        width: 100%;
        padding-left: 100px;
    }

    .box-text .title {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 64px;
    }

    .box-text .line {
        width: 5px;
        height: 48px;
        background-color: #BF9D77;
        margin-right: 18px;
    }

    .box-text .text {
        font-weight: bold;
        font-size: 32px;
        color: #383838;
        letter-spacing: 0;
        line-height: 48px;
        flex: 1;
    }

    .box-text .info {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-bottom: 56px;
        text-decoration: none;
    }

    .box-text .info:last-child {
        margin-bottom: 0;

    }

    .box-text .icon {
        position: relative;
        margin-right: 16px;
        height: 72px;
    }

    .box-text .icon img {
        display: block;
        width: 72px;
        z-index: 2;
        position: relative;
    }

    .box-text .icon .block {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 36px;
        height: 36px;
        background-color: #BF9D77;
    }

    .box-text .textbox {
        flex: 1;
        margin-left: 16px;
        position: relative;
        top: 10px;
    }

    .box-text .key {
        font-weight: bold;
        font-size: 18px;
        color: #383838;
        letter-spacing: 0;
        line-height: 25px;
    }

    .box-text .value {
        font-size: 14px;
        color: #383838;
        letter-spacing: 0;
        line-height: 20px;
        text-decoration: none;
        display: block;
    }

    @media screen and (max-width:768px) {
        .map-contant {
            width: 100%;
            max-width: 1170px;
            display: block;
            margin: 96px auto 0;
        }

        .box-map {
            width: 100%;
        }

        .map-pc {
            display: none;
        }

        .map-mobile {
            background-color: #ccc;
            width: 100%;
            display: block;
        }
        .map-mobile img{
            width: 100%;
            display: block;
        }

        .box-text {
            width: 86%;
            margin: 54px auto 0;
            max-width: 100%;
        }

        .box-text .wrap {
            padding-left: 0;
        }

        .box-text .title {
            margin-bottom: 54px;
        }

        .box-text .line {
            width: 3px;
            height: 25px;
            margin-right: 11px;
        }

        .box-text .text {
            font-size: 18px;
            line-height: 25px;
        }

        .box-text .info {
            display: block;
            margin-bottom: 54px;
        }

        .box-text .info:last-child {
            margin-bottom: 0;

        }

        .box-text .icon {
            margin-right: 0;
            width: 72px;
            margin: 0 auto;
            position: relative;
            left: -10px;
        }

        .box-text .textbox {
            margin-left: 0;
            top: 12px;
        }

        .box-text .key {
            font-weight: bold;
            font-size: 16px;
            text-align: center;
            line-height: 22px;
        }

        .box-text .value {
            font-size: 12px;
            text-align: center;
            line-height: 17px;
            margin-top: 8px;
        }
    }
</style>