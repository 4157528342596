<template>
    <div class="banner">
        <div class="title">
            <div class="line"></div>
            <div class="text">
                <!-- 关于我们 -->
                {{ $t("about.bannerText") }}
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'AboutBanner',
        props: {
            active: String
        },
        data() {
            return {
                loading: false
            }
        },
        mounted() {
            this.init()
        },
        methods: {
            init() {}
        }
    }
</script>

<style scoped>
    .banner {
        width: 100%;
        height: 480px;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        background-image: url('../assets/about/about-banner.jpg');
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
            position: relative;
    }

    .title {
        display: none;
    }

    @media screen and (max-width:768px) {
        .banner {
            height: 375px;
            margin-top: 96px;
        }

        .title {
            position: absolute;
            left: 28px;
            top:36px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-self: center;
        }
        .title .line {
            width: 6px;
            height: 33px;
            background-color: #BF9D77;
            margin-right: 8px;
        }
        .title .text {
            font-weight: bold;
            font-size: 24px;
            color: #FFFFFF;
            letter-spacing: 0;
        }
    }
</style>