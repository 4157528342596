<template>
  <div class="container">
    <Navbar active="coffee" class="component-navber" />
    <CoffeeBanner />
    <CoffeeList :cat="cat" :coffeeInfo="coffeeInfo" />
    <Bottom />
  </div>
</template>

<script>
  const axios = require('axios');
  import Navbar from "../components/Navbar"
  import CoffeeBanner from "../components/CoffeeBanner"
  import CoffeeList from "../components/CoffeeList"
  import Bottom from "../components/Bottom"

  export default {
    components: {
      Navbar,
      Bottom,
      CoffeeBanner,
      CoffeeList
    },
    name: 'Index',
    // props: {
    //   msg: String
    // },
    data() {
      return {
        loading: false,
        coffeeInfo: [],
        cat: [],
      }
    },
    mounted() {
      this.init()
    },
    methods: {
      init() {
        // this.loadCat()
        this.loadCoffee()
        
      },
      // loadCat() {
      //   let self = this
      //   // Make a request for a user with a given ID
      //   axios.get('https://api.kolakolacoffee.com/coffee-classifications?_limit=-1&_sort=sort:ASC')
      //     .then(function (response) {
      //       // handle success
      //       // console.log(response);
      //       self.cat = response.data
      //     })
      //     .catch(function (error) {
      //       // handle error
      //       console.log(error);
      //     })
      // },
      loadCoffee() {
        let self = this
        // Make a request for a user with a given ID
        axios.get('https://api.kolakolacoffee.com/coffees?_limit=-1&_sort=updated_at:DESC')
          .then(function (response) {
            // handle success
            // console.log(response);
            self.coffeeInfo = response.data
          })
          .catch(function (error) {
            // handle error
            console.log(error);
          })
      }
    }
  }
</script>

<style scoped>
  .component-navber {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
  }

  @media screen and (max-width:768px) {
    .component-navber {
      display: block;
      width: 100%;
      height: 96px;
      background-color: #34302F;
    }
  }
</style>