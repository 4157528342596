<template>
    <div class="video-box">
        <div class="videobox">
            <video 
                controls
                preload="auto"
                poster="../assets/index/index-video-poster.jpg"
                loop
                muted>
            <!-- Todo 移动端视频优化 -->
                <source src="../assets/video/index-intro.mp4" type="video/mp4">
                <source src="../assets/video/index-intro.webm" type="video/webm">
                <p class="vjs-no-js">
                    To view this video please enable JavaScript, and consider upgrading to a
                    web browser that
                    <a href="https://videojs.com/html5-video-support/" target="_blank">supports HTML5 video</a>
                </p>
            </video>
        </div>
        <div class="textbox">
            <div class="title">
                <div class="line"></div>
                <div class="text">
                    <!-- 比想象更专业 -->
                     {{ $t("factory.videoTitle") }}
                </div>
            </div>
            <div class="intro">
                <!-- 专业认证食品级烘焙工厂，科学化管理，智能先进设备，自动化烘焙机，客定烘焙方案，自动化可复制操作，稳定烘焙，为您定制独一无二。原材料库，成品仓储，恒温恒湿。我们坚持对好风味的如一追求，为您甄选独一的优质风味。 -->
                     {{ $t("factory.videoIntro") }}
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'FactoryVideo',
        props: {
            active: String,
            screenWidth: document.body.clientWidth,
        },
        data() {
            return {
                loading: false
            }
        },
        mounted() {
            this.init()
        },
        methods: {
            init() {}
        }
    }
</script>

<style scoped>
    .video-box {
        width: 86%;
        max-width: 994px;
        margin: 160px auto;
    }

    .videobox {
        width: 100%;
    }

    .videobox video {
        width: 100%;
    }

    .textbox {
        width: 100%;
    }

    .title {
        margin-top: 64px;
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .line {
        width: 6px;
        height: 40px;
        background-color: #BF9D77;
        margin-right: 24px;
    }

    .text {
        flex: 1;
        font-weight: bold;
        font-size: 32px;
        color: #383838;
        letter-spacing: 0;
    }

    .intro {
        font-size: 14px;
        color: #383838;
        letter-spacing: 0;
        text-align: left;
        line-height: 28px;
        margin-top: 24px;
        padding-left: 30px;
    }

    .lang-zh .intro {
        text-align: justify;
    }

    @media screen and (max-width:768px) {

        .video-box {
            margin: 63px auto;
        }

        .textbox {
            width: 100%;
        }

        .title {
            margin-top: 36px;
            display: flex;
            flex-direction: row;
            align-items: center;
        }

        .line {
            width: 3px;
            height: 25px;
            margin-right: 16px;
        }

        .text {
            flex: 1;
            font-weight: bold;
            font-size: 18px;
        }

        .intro {
            font-size: 12px;
            margin-top: 16px;

            padding-left: 0;

        }

    }
</style>