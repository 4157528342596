<template>
    <div class="box-swiper">
        <!-- Slider main container -->
        <div class="swiper-container" id="swiper">
            <!-- Additional required wrapper -->
            <div class="swiper-wrapper">
                <!-- Slides -->
                <div class="swiper-slide">
                    <div class="box-slide">
                        <div class="imgbox">
                            <img src="../assets/factory/factory-img-4.jpg" alt="">
                        </div>
                        <div class="textbox">
                            <div class="title">
                                <div class="line"></div>
                                <div class="text">
                                    <!-- 专业认证 -->
                                    {{ $t("factory.swiper.title1") }}
                                </div>
                            </div>
                            <div class="intro">
                                <!-- 专业认证食品级烘焙工厂，科学化管理，智能先进设备，自动化烘焙机，客定烘焙方案，自动化可复制操作，稳定烘焙，为您定制独一无二。 -->
                                {{ $t("factory.swiper.intro1") }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="swiper-slide">
                    <div class="box-slide">
                        <div class="imgbox">
                            <img src="../assets/factory/factory-img-4.jpg" alt="">
                        </div>
                        <div class="textbox">
                            <div class="title">
                                <div class="line"></div>
                                <div class="text">
                                    <!-- 先进设备 -->
                                    {{ $t("factory.swiper.title2") }}
                                </div>
                            </div>
                            <div class="intro">
                                <!-- 专业认证食品级烘焙工厂，科学化管理，智能先进设备，自动化烘焙机，客定烘焙方案，自动化可复制操作，稳定烘焙，为您定制独一无二。 -->
                                {{ $t("factory.swiper.intro2") }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="swiper-slide">
                    <div class="box-slide">
                        <div class="imgbox">
                            <img src="../assets/factory/factory-img-4.jpg" alt="">
                        </div>
                        <div class="textbox">
                            <div class="title">
                                <div class="line"></div>
                                <div class="text">
                                    <!-- 定制方案 -->
                                    {{ $t("factory.swiper.title3") }}
                                </div>
                            </div>
                            <div class="intro">
                                <!-- 专业认证食品级烘焙工厂，科学化管理，智能先进设备，自动化烘焙机，客定烘焙方案，自动化可复制操作，稳定烘焙，为您定制独一无二。 -->
                                {{ $t("factory.swiper.intro3") }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    // import Swiper JS
    import Swiper from 'swiper';
    // import Swiper styles
    import 'swiper/swiper-bundle.css';
    export default {
        name: 'FactorySwiper',
        props: {
            active: String
        },
        data() {
            return {
                loading: false
            }
        },
        mounted() {
            this.init()
        },
        methods: {
            init() {
                this.startSwiper()
            },
            // Todo 屏幕缩放，自适应大小
            startSwiper() {
                let width = document.body.clientWidth
                if (width > 768) {
                    // PC
                    const swiper = new Swiper('#swiper', {
                        // Optional parameters
                        direction: 'horizontal',
                        loop: true,
    
                        slidesPerView: 3,
                        spaceBetween: 30,
                    });
                } else {
                    // Mobile
                    const swiper = new Swiper('#swiper', {
                        // Optional parameters
                        direction: 'horizontal',
                        loop: false,
                        slidesPerView: 1.2,
                        spaceBetween: 0,
                    });


                }
            }
        }
    }
</script>

<style scoped>
    .box-swiper {
        width: 86%;
        max-width: 1200px;
        margin: 161px auto 0;
    }

    .swiper-container {
        width: 100%;
    }

    .swiper-slide {
        width: 100%;
    }

    .box-slide{
        width: 100%;
    }

    .imgbox {
        width: 100%;
    }

    .imgbox img {
        width: 100%;
        display: block;
    }

    .textbox {
        margin-top: 32px;
    }

    .title {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    .title .line {
        width: 5px;
        height: 32px;
        background-color: #BF9D77;
        margin-right: 12px;
    }

    .title .text {
        flex: 1;
        font-weight: bold;
        font-size: 24px;
        color: #383838;
        letter-spacing: 0
    }

    .lang-zh .title .text {
        letter-spacing: 10px;
    }

    .intro {
        margin-top: 24px;
        font-size: 14px;
        color: #383838;
        letter-spacing: 0;
        text-align: left;
        line-height: 28px;
    }

    .lang-zh .intro {
        text-align: justify;
    }

    @media screen and (max-width:768px) {
        .box-swiper {
            width: 100%;
            margin: 63px auto 0;
        }

        .swiper-slide {
            position: relative;
        }


        .box-slide{
            box-sizing: border-box;
            width: 100%;
            padding: 0 7%;
        }

        .textbox {
            margin-top: 36px;
        }

        .title {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
        }

        .title .line {
            width: 3px;
            height: 25px;
            background-color: #BF9D77;
            margin-right: 14px;
        }

        .title .text {
            font-size: 18px;
        letter-spacing: 0;
        }

        .intro {
            margin-top: 16px;
            font-size: 12px;
        }
    }
</style>