<template>
    <div class="videobox">
        <img src="../assets/index/index-videobg-pc.png" alt="" class="bg-pc">
        <img src="../assets/index/index-videobg-mobile.png" alt="" class="bg-mobile">
        <div class="wrap">
            <div class="text-box">
                <div class="title">
                    <div class="line"></div>
                    <p>
                        <!-- 我们是一家怎样的公司？ -->
                        {{ $t("home.video.title") }}
                    </p>
                </div>
                <video class="video-mobile"
                    controls
                    preload="auto"
                    poster="../assets/index/index-video-poster.jpg"
                    loop
                    muted>
                    <source src="../assets/video/index-intro.mp4" type="video/mp4">
                    <source src="../assets/video/index-intro.webm" type="video/webm">
                    <p class="vjs-no-js">
                        To view this video please enable JavaScript, and consider upgrading to a
                        web browser that
                        <a href="https://videojs.com/html5-video-support/" target="_blank">supports HTML5 video</a>
                    </p>
                </video>
                <div class="intro">
                    <!-- KOLAKOLA 咖乐乐创立于2019年，全国优质烘焙品 牌之一，集合丰富资源，专业呈现。为您提供稳定，高品质的综合保障。 “WE PICK THE BEST FOR YOU
                    ”我们致力于为您烘焙精品咖啡，呈现优质风味。 -->
                    {{ $t("home.video.intro") }}
                </div>
                <div class="more" @click="go('about')">
                    <!-- 了解更多 -->
                    {{ $t("home.video.more") }} ></div>
                <!-- Todo -->
            </div>
            <div class="video-box">
                <video
                    controls
                    preload="auto"
                    poster="../assets/index/index-video-poster.jpg"
                    loop
                    :autoplay="videoAuto"
                    muted>
                    <source src="../assets/video/index-intro.mp4" type="video/mp4">
                    <p class="vjs-no-js">
                        To view this video please enable JavaScript, and consider upgrading to a
                        web browser that
                        <a href="https://videojs.com/html5-video-support/" target="_blank">supports HTML5 video</a>
                    </p>
                </video>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'HomeVideo',
        props: {
            active: String
        },
        components: {},
        data() {
            return {
                // loading: false,
                videoAuto: false
            }
        },
        mounted() {
            this.init()
        },
        methods: {
            init() {
                let width = document.body.clientWidth
                if(width > 768){
                    this.videoAuto = true
                }
            },
            go(where){
                this.$router.push('/'+where)
                // 返回顶部
                document.body.scrollIntoView()
            }
        }
    }
</script>

<style scoped>
    .videobox {
        margin-top: 120px;
        padding: 75px 0;
        background-color: #34302F;
        overflow: hidden;
        position: relative;
    }

    .bg-pc {
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        display: block;
    }

    .bg-mobile {
        display: none;
    }

    .wrap {
        width: 86%;
        max-width: 1200px;
        margin: 0 auto;
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        z-index: 2;
    }

    .text-box {
        flex: 2;
        width: 1px;
        margin-right: 30px;
    }

    .text-box .title {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    .text-box .title .line {
        height: 40px;
        width: 5px;
        margin-right: 19px;
        background-color: #BF9D77;
    }

    .text-box .title p {
        flex: 1;
        font-weight: bold;
        font-size: 32px;
        color: #FFFFFF;
        letter-spacing: 0;
        line-height: 40px;
        margin: 0;
    }

    .video-mobile {
        display: none;
    }

    .text-box .intro {
        font-size: 14px;
        color: #fff;
        letter-spacing: 0;
        line-height: 28px;
        margin-top: 28px;
        padding-left: 24px;
    }

    .text-box .more {
        font-weight: bold;
        font-size: 16px;
        color: #FFFFFF;
        letter-spacing: 0;
        margin-top: 24px;
        padding-left: 24px;
        cursor: pointer;
    }

    .text-box .more:hover {
        transform: translateX(5px);
    }

    .video-box {
        flex: 3;
        width: 1px;
    }

    .video-box video {
        width: 100%;
    }

    @media screen and (max-width:768px) {
        .videobox {
            margin-top: 48px;
            padding: 35px 0 28px;
        }

        .bg-pc {
            display: none;
        }

        .bg-mobile {
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
            display: block;

        }

        .wrap {
            width: 86%;
            display: block;
        }

        .text-box {
            width: 100%;
            margin-right: 0;
        }

        .text-box .title {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
        }

        .text-box .title .line {
            height: 20px;
            width: 3px;
            margin-right: 8px;
        }

        .text-box .title p {
            flex: 1;
            font-size: 18px;
            line-height: 20px;
            margin: 0;
        }

        .video-mobile {
            margin-top: 19px;
            display: block;
            width: 100%;
        }

        .text-box .intro {
            font-size: 12px;
            line-height: 28px;
            margin-top: 12px;
            padding: 0 27px;
        }

        .text-box .more {
            font-size: 12px;
            text-align: right;
            margin-top: 12px;
            padding: 0 27px;
        }

        .video-box {
            display: none;
        }
    }
</style>