<template>
    <div class="contant">
        <div class="info-box">
            <div class="imgbox">
                <img class="img-1" src="../assets/factory/factory-img-1.jpg" alt="">
                <img class="img-2" src="../assets/factory/factory-img-2.jpg" alt="">
                <img class="img-3" src="../assets/factory/factory-img-3.jpg" alt="">
            </div>
            <div class="textbox">
                <div class="title">
                    <div class="line"></div>
                    <div class="text">
                        <!-- 水平与资质 -->
                        {{ $t("factory.contantTitle") }}
                    </div>
                </div>
                <div class="intro">
                    <div class="intro-text">
                        <!-- 5年资深经验咖啡烘焙师，精湛的烘焙技术，稳定商品的高质量标准。10年资深品控，把握品质，只为您甄选，甄选优质风味。 -->
                        {{ $t("factory.contantIntro") }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'FactoryContant',
        props: {
            active: String
        },
        data() {
            return {
                loading: false
            }
        },
        mounted() {
            this.init()
        },
        methods: {
            init() {}
        }
    }
</script>

<style scoped>
    .contant {
        width: 86%;
        max-width: 995px;
        margin: 120px auto 0;
    }

    .info-box {
        width: 100%;
        display: flex;
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
    }


    .textbox {
        flex: 1;

        margin-right: 30px;
    }

    .title {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 78.8%;
    }

    .title .line {
        width: 5px;
        height: 40px;
        background-color: #BF9D77;
        margin-right: 19px;
    }

    .title .text {
        font-weight: bold;
        font-size: 32px;
        color: #383838;
        letter-spacing: 0;
        line-height: 40px;
        flex: 1;
    }

    .intro {
        margin-top: 26px;
        width: 100%;
    }

    .intro-text {
        padding-left: 24px;
        font-size: 14px;
        color: #383838;
        letter-spacing: 0;
        text-align: left;
        line-height: 28px;
    }

    .lang-zh .intro-text {
        text-align: justify;
    }

    .imgbox {
        flex: 1;
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .imgbox img{
        flex:1;
        display: block;
        width: 33.33%;
    }

    @media screen and (max-width:768px) {
        .contant {
            margin: 54px auto 0;
        }

        .info-box {
            display: block;
            margin-top: 37px;
        }

        .imgbox {
            width: 100%;
        }


        .textbox {
            flex: 1;
            margin-top: 32px;
        }

        .title {
            width: 100%;
        }

        .title .line {
            width: 3px;
            height: 25px;
            margin-right: 11px;
        }

        .title .text {
            font-weight: bold;
            font-size: 18px;
            color: #383838;
            letter-spacing: 0;
            line-height: 25px;
            flex: 1;
        }

        .intro {
            margin-top: 24px;
            font-size: 12px;
            width: 100%;
        }

        .intro-text {
            padding-left: 0;
            font-size: 12px;
        }
    }
</style>