<template>
  <div class="container">
    <Navbar active="index" class="component-navber" />
    <HomeBanner />
    <HomeNavigation />
    <HomeVideo />
    <HomeCoffeeIntro />
    <Bottom />
  </div>
</template>

<script>
  import Navbar from "../components/Navbar"
  import HomeBanner from "../components/HomeBanner"
  import HomeNavigation from "../components/HomeNavigation"
  import HomeVideo from "../components/HomeVideo"
  import HomeCoffeeIntro from "../components/HomeCoffeeIntro"
  import Bottom from "../components/Bottom"
  export default {
    components: {
      Navbar,HomeBanner,HomeNavigation,HomeVideo,HomeCoffeeIntro,Bottom
    },
    name: 'Index',
    // props: {
    //   msg: String
    // },
    data() {
      return {
        loading: false
      }
    },
    mounted() {
      this.init()
    },
    methods: {
      init() {}
    }
  }
</script>

<style scoped>
  .component-navber {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
  }
</style>